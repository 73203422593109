h2 {
  color: #0066ff;
  font-size: 24px;
  font-weight: 600;
  margin: 0px;
}

.buttons__text {
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.0083em;
  margin: 0;
}
