.App {
  text-align: center;
  min-height: 100vh;
  background-color: #f6f9fb;
}

*::-webkit-scrollbar {
  width: 20px;
}

*::-webkit-scrollbar
{
  background-color: transparent;
}

*::-webkit-scrollbar-thumb
{
	background-color: #d6dee1;
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
}

*::-webkit-scrollbar-thumb:hover {
  background-color: #a8bbbf;
}
